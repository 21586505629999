<template>
  <b-row>
    <b-col md="2">
      <template #title>
        <feather-icon icon="CodepenIcon" />
        <span>Branch Details</span>
      </template>
    </b-col>
    <b-col md="3" class="mb-1">
      <b-form-group>
          <v-select v-model="pdfType"
            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'" :options="pdf_type"
            placeholder=" Select Type" @input="refresh" />
      </b-form-group>
    </b-col>
    <b-col md="3">
      <b-form-group>
        <b-form-datepicker v-model="date" placeholder="Select date" dropright reset-button @input="refresh" />
      </b-form-group>
    </b-col>
    <b-col md="3">
      <template>
        <!-- Add a button for exporting PDF beside each branch -->
        <b-button   @click="exportPdf(pdfType.value,branchId, date)" variant="primary">
          {{ $t('Export PDF') }} 
        </b-button>
      </template>
    </b-col>
  </b-row>
</template>


<script>
import {
  BCard,
  BRow,
  BCol,
  BFormDatepicker,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BOverlay,
  BCardHeader,
  BCardBody,
  BModal,
  VBModal,
  BPopover,
  BFormGroup,
  BForm,
} from "bootstrap-vue";
import { mapGetters, mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { avatarText } from "@core/utils/filter";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required, email } from "@validations";
import DataTable from "@/views/components/table/DataTable";

export default {
  components: {
    DataTable,
    BFormGroup,
    BFormDatepicker,
    BCard,
    vSelect,
    BRow,
    BCol,
    BOverlay,
    BCardHeader,
    BCardBody,
    BForm,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    VBModal,
    BPopover,
    ValidationProvider,
    ValidationObserver,
    localize,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      pdf_type:[
        {'value': 0, 'label': 'Automatic Check'},
        {'value': 1, 'label': 'Manual Check'},
      ],
      pdfType: null,
      date: '',
      branchId:null,
      user_id: null,
      user_name: null,
    };
  },
  props: {
    logs: {
      type: Boolean,
      default: false,
    },

    statis: false,
  },

  computed: {
    // ...mapGetters({
    //   items: "branches/items",
    //   total: "branches/total",
    //   load: "branches/load",
    //   lookups: "branches/lookups",
    // }),
    //   lookups(){

    //   let lookups =  [ {id: 25, name: "MAGRABI Hospital & - Makkah", code: "25", type: null}];
    //   return lookups

    //   },

    title() {
      if (this.user_name) {
        return (
          this.$t("Global.userAccessControl") + " ( " + this.user_name + " )"
        );
      } else {
        return this.$t("Global.userAccessControl");
      }
    },

  },
  methods: {
    exportPdf(pdfType ,branchId, date) {
      console.log("sss",pdfType,branchId,date);
      // Open a new window or tab with the PDF export route
      if(pdfType == 0)
      window.open(`/api/pdf/${branchId}/0/${date}`, '_blank');
      else
      window.open(`/api/manual/pdf/${branchId}/${pdfType}/${date}`, '_blank');
    },
    ...mapActions({
      getLookups: "app/GET_LOOKUPS",
      setAccessControl: "users/setAccessControl",
    }),
    fetchLookups() {
      this.getLookups(this.lookupModules).then((data) => {
        this.allLookups = data.success;
      });
    },

    openDialog(item) {
      this.user_id = item.id;
      this.user_name = item.name;
      this.ruleForm.faculties_ids = item?.access?.faculties_ids;
      this.dialog = true;
    },
    open(id) {
      window.open(
        this.$router.resolve({ name: "user-edit", params: { id } }).href,
        "_blank"
      );
    },
    refresh(query) {
      query = { ...query, type: this.type };

      this.$store.dispatch("branches/branches", { query });
    },

  },
  mounted() {
    this.branchId = this.$route.params.id;
    // this.fetchLookups();
    console.log('Branch ID:', this.branchId);

  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
th {
  color: blue;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
